<template>
  <div>
    <h2>Partner Dashboard</h2>
    <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="UsersIcon"
          color="primary"
          statistic-title="On Hold"
          :statistic="data.onHoldVerifications"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="UserCheckIcon"
          color="warning"
          statistic-title="Asssigned to you"
          :statistic="data.assignedToPartnerVerifications"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="CheckIcon"
          color="success"
          statistic-title="Verified by you"
          :statistic="data.verifiedPartnerVerifications"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="CheckIcon"
          color="success"
          statistic-title="Verified / Completed"
          :statistic="data.verifiedVerifications"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="UserXIcon"
          color="danger"
          statistic-title="Rejected by you"
          :statistic="data.rejectedPartnerVerifications"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="XIcon"
          color="danger"
          statistic-title="Rejected by Staff"
          :statistic="data.rejectedVerifications"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="CreditCardIcon"
          color="danger"
          statistic-title="Refunded"
          :statistic="data.refundedVerifications"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="ArchiveIcon"
          color="primary"
          statistic-title="Archived"
          :statistic="data.archiveVerifications"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="9"
        sm="12"
      >
        <b-card
          no-body
        ><b-card-header>Verification by User Types</b-card-header>
          <b-table
            :items="data.types"
            :fields="userTypeFields"
          />
        </b-card>
      </b-col>
      <b-col
        md="3"
        sm="12"
      ><statistic-card-vertical
         icon="UsersIcon"
         color="success"
         statistic-title="Total Verifications"
         :statistic="data.totalVerifications"
       />
        <statistic-card-vertical
          icon="PercentIcon"
          color="success"
          statistic-title="Completed Percentage"
          :statistic="data.completedPercentage"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BTable, BCard, BCardHeader,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import axios from 'axios'

export default {
  name: 'PartnerDashboard',
  components: {
    BRow,
    BCol,
    BTable,
    BCardHeader,
    BCard,
    StatisticCardVertical,
  },
  data() {
    return {
      data: {
        onHoldVerifications: '-',
        assignedToPartnerVerifications: '-',
        verifiedPartnerVerifications: '-',
        verifiedVerifications: '-',
        rejectedPartnerVerifications: '-',
        rejectedVerifications: '-',
        refundedVerifications: '-',
        archiveVerifications: '-',
        totalVerifications: '-',
        completedPercentage: '-%',
        types: [
          {
            userType: 'Freelancer',
            total: 0,
            pending: 0,
            rejected: 0,
            completed: 0,
          },
          {
            userType: 'Company',
            total: 0,
            pending: 0,
            rejected: 0,
            completed: 0,
          },
          {
            userType: 'Advisor',
            total: 0,
            pending: 0,
            rejected: 0,
            completed: 0,
          },
          {
            userType: 'Broker',
            total: 0,
            pending: 0,
            rejected: 0,
            completed: 0,
          },
          {
            userType: 'Investor',
            total: 0,
            pending: 0,
            rejected: 0,
            completed: 0,
          },
        ],
      },
      userTypeFields: [
        { key: 'userType', label: 'User Type' },
        { key: 'total', label: 'Total' },
        { key: 'pending', label: 'Pending' },
        { key: 'rejected', label: 'Rejected' },
        { key: 'completed', label: 'Completed / Accpted' },
      ],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      axios.get('/dashboard/partners').then(response => {
        const {
          onHoldVerifications,
          assignedToPartnerVerifications,
          verifiedPartnerVerifications,
          verifiedVerifications,
          rejectedPartnerVerifications,
          rejectedVerifications,
          refundedVerifications,
          archiveVerifications,
          totalVerifications,
          freelancer,
          company,
          broker,
          advisor,
          investor,
        } = response.data

        freelancer.userType = 'Freelancer'
        company.userType = 'Company'
        broker.userType = 'Broker'
        advisor.userType = 'Advisor'
        investor.userType = 'Investor'

        this.data = {
          onHoldVerifications,
          assignedToPartnerVerifications,
          verifiedPartnerVerifications,
          verifiedVerifications,
          rejectedPartnerVerifications,
          rejectedVerifications,
          refundedVerifications,
          archiveVerifications,
          totalVerifications,
          completedPercentage:
            verifiedPartnerVerifications > 0
              ? `${((verifiedPartnerVerifications / totalVerifications) * 100).toFixed(2)}%`
              : '0%',
          types: [freelancer, company, advisor, broker, investor],
        }
      })
    },
  },
}
</script>
